<template>
    <div class='put-index'>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
  name: 'putIndex'
}
</script>
<style lang="scss" scoped>
    .put-index{

    }
</style>
